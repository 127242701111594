import * as React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import { RichText } from "prismic-reactjs"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostItem from "../components/postItem"
import AdSlot from "../components/shared/adSlot"
import PrismicLink from "../components/prismicLink"

const BlogPage = ({ data }) => {
    const document = data.prismicBlogPage.data

    return (
        <Layout>
            <SEO
                title={
                    document.seo_title.text ||
                    RichText.asText(document.page_title.raw)
                }
                description={document.seo_description?.text}
                ogImage={document.og_image?.url}
            />
            <div className="bg-gray-100">
                {/* Other Slot */}
                <AdSlot html={data.settings.data.other_slot?.text} />
            </div>
            <div className="grid lg:grid-cols-3 gap-16 mx-auto px-6 pt-12 max-w-7xl">
                <div className="lg:col-span-2">
                    <div className="mb-6">
                        <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-snug">
                            {RichText.asText(document.page_title.raw)}
                        </h1>
                    </div>
                    <div className="pb-20">
                        <RichText
                            className="prose prose-lg max-w-none text-gray-600"
                            render={document.page_content.raw}
                            serializeHyperlink={PrismicLink}
                        />

                        <ul>
                            {data.posts.nodes.map(post => (
                                <PostItem
                                    data={data}
                                    post={post}
                                    key={post.uid}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default withPreview(BlogPage)

export const query = graphql`
    query BlogQuery {
        settings: prismicGeneralSettings {
            data {
                other_slot {
                    text
                }
            }
        }
        prismicBlogPage {
            uid
            data {
                page_title {
                    raw
                }
                page_content {
                    raw
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                og_image {
                    url
                }
            }
        }
        posts: allPrismicPost(
            sort: { fields: first_publication_date, order: DESC }
        ) {
            nodes {
                uid
                first_publication_date(formatString: "MMMM Do, YYYY")
                data {
                    post_title_alt {
                        raw
                    }
                    post_title {
                        raw
                    }
                    post_link {
                        link_type
                        type
                        uid
                        url
                    }
                }
            }
        }
    }
`
