import * as React from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import CustomLink from "./customLink"

const PostItem = ({ data, post }) => (
    <li>
        <h2>
            {post.data.post_link?.link_type !== "Any" && (
                <CustomLink
                    className="inline-block mb-2 px-4 py-2 md:text-lg text-white rounded-lg bg-blue-700 hover:bg-blue-900 transition"
                    element={post.data.post_link}
                    content={RichText.asText(
                        post.data.post_title_alt?.raw ||
                            post.data.post_title?.raw
                    )}
                />
            )}
            {post.data.post_link?.link_type === "Any" && (
                <Link
                    className="inline-block mb-2 px-4 py-2 md:text-lg text-white rounded-lg bg-blue-700 hover:bg-blue-900 transition"
                    to={`/${data.prismicBlogPage.uid}/${post.uid}`}
                    title={RichText.asText(
                        post.data.post_title_alt?.raw ||
                            post.data.post_title?.raw
                    )}
                >
                    {RichText.asText(
                        post.data.post_title_alt?.raw ||
                            post.data.post_title?.raw
                    )}
                </Link>
            )}
        </h2>
    </li>
)

export default PostItem
